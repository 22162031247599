@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #fbfbfb !important;
  font-family: "Public Sans", sans-serif !important;
}

.sign-banner-part {
  display: flex;
  align-items: center;
}

.left-banner-img {
  background-image: url("../Images/banner-sign.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.logo {
  margin-left: 46px;
  margin-top: 20px;
  position: absolute;
  top: 20px;
  z-index: 1;
}

.logo img {
  width: 140px;
}

.login-box-inner-wrap {
  max-width: 550px;
  margin: auto;
  padding: 45px;
  min-height: 450px;
}

.login-box-inner-wrap h2 {
  color: #444151;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.6px;
}

.login-box-inner-wrap p {
  font-size: 15px;
  font-weight: 400;
  color: #6e6b78;
  font-family: "Public Sans", sans-serif;
}

.login-box-inner-wrap form {
  margin-top: 35px;
}

.login-box-inner-wrap label {
  color: #444151;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 6px;
  height: 42px;
}

.login-box-inner-wrap input:focus {
  box-shadow: none;
  border-color: #d1d0d4;
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.login-box-inner-wrap input::placeholder {
  color: #acaab1;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.pass-rember-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remember-check {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remember-check input {
  height: 16px;
  width: 16px;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 7px;
  height: 42px;
}

/* .login-box-inner-wrap label {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
} */

.forgot {
  color: #158a44;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
}

button.submit {
  margin-top: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  text-decoration: none;
  margin: auto;
  border-radius: 7px;
  background: #158a44;
  border: none;
}

button.submit:hover {
  background-color: #158a44;
}

.btn-primary:active {
  background-color: #158a44 !important;
}

.otp-banner-img {
  background-image: url("../Images/otp-banner.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.forgot-btn {
  margin-top: 30px !important;
}

.back-link-ad {
  text-align: center;
  margin-top: 20px;
}

.back-link-ad a {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  gap: 6px;
  color: #158a44;
  text-decoration: none;
}

.reset-banner-img {
  background-image: url("../Images/reset-pass.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.error-banner-img {
  background-image: url("../Images/error-bg.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
}

.error-text-box {
  max-width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 90px;
}

.error-text-box h2 {
  font-size: 96px;
  font-style: normal;
  font-weight: 500;
  line-height: 96px;
}

.error-text-box > div {
  /* Basic Typography/h4 */
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  /* 158.333% */
}

.error-text-box p {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 11px;
  color: #6c6976;
}

.error-text-box a {
  padding: 10px 15px;
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  display: block;
  max-width: 140px;
  margin: auto;
  border-radius: border-radius-md;
  background: linear-gradient(270deg, rgba(21, 138, 68, 0.7) 0%, #158a44 100%);
  box-shadow: 0px 2px 6px 0px #158a44;
}

.maintenance-banner-img {
  background-image: url("../Images/under-maintenance.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
}

.resend-mail p {
  text-align: center;
  margin-top: 20px;
}

.resend-mail p a {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-decoration: none;
  color: #158a44;
}

.login-box-inner-wrap p span {
  font-size: 15px;
  color: #444151;
  font-weight: 400;
}

.new-otp-banner-img {
  background-image: url("../Images/banner-otp.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* dashboard page css */

.main-wrap {
  display: flex;
  height: 100vh;
}

aside {
  background: #fff;
  width: 270px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1;
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
}

.toggle-icon {
  position: fixed;
  left: 245px;
  border: 2px solid #fff;
  padding: 5px 6px;
  background: #071523;
  filter: drop-shadow(0px 6px 10px rgba(151, 151, 151, 0.47));
  width: 40px;
  height: 40px;
  top: 70px;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 62px;
  align-items: center;
  cursor: pointer;
}

.toggle-icon img {
  width: 20px;
  filter: invert(1);
}

.logo-side img {
  width: 134px;
  margin: 25px 23px;
}

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 170px);
}

.side-menu .side-btm-space {
  margin-bottom: 5px;
}

.side-menu a.bar_active {
  color: #ffffff;
  margin: 0px 10px;
  padding: 12px 15px;
  border-radius: 6px;
  background: linear-gradient(270deg, rgba(21, 138, 68, 0.7) 0%, #158a44 100%);
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}

.side-menu a {
  color: #000;
  display: flex !important;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  padding: 10px 25px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-decoration: none;
  display: block;
  align-items: center;
}

.side-menu a.bar_active {
  color: #ffffff;
}

.side-menu a {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

a.menu-btn.bar_active path {
  stroke: #fff;
}

.side-menu a svg {
  margin-right: 13px;
  width: 22px;
}

.side-menu a p {
  margin: 0;
}

.right-side-cmn {
  width: calc(100% - 270px);
  position: relative;
  margin-left: auto;
  background: #fbfbfb;
  height: 100vh;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.right-top-bar {
  border-radius: var(--Border-Radius-border-radius-md, 6px);
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  padding: 4px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.left-side-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-side-bar input {
  border: 0 !important;
  background-color: transparent;
  outline: none !important;
}

.left-side-bar input::placeholder {
  color: #acaab1;
  font-size: 15px;
  font-weight: 400;
}

.right-side-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.right-top-bar .user-img-top img {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.select-box {
  display: flex;
  justify-content: center;
  width: 100%;
}

.dashbox-inner-wrap select.form-select {
  border: 1px solid #b9b5ab;
  background: #fff;
  color: #000;
  font-family: "Public Sans", sans-serif;
  height: 40px;
  width: 200px;
  padding-left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
  background-image: url("../Images/Expand_down.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.dash-inner-boxes {
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  padding: 25px 22px;
  margin-top: 10px;
  border-radius: 6px;
}

.session {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
}

.left-session p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #444151;
  margin-bottom: 5px;
}

.left-session h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  color: #444151;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-session h2 span {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #28c76f;
}

span.total-users {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #6e6b78;
}

.filters {
  margin-left: 10px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  border-radius: 6px;
  margin-top: 20px;
}

.user-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 22px;
}

.search-tab {
  display: flex;
  align-items: center;
  gap: 10px;
}

.export-box p {
  margin: 0px;
  display: flex;
  gap: 9px;
  align-items: center;
}

.export-box p {
  margin: 0px;
  display: flex;
  gap: 9px;
  align-items: center;
  color: #808390;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.export-box {
  background: #eaebed;
  padding: 8px 17px;
  border-radius: 6px;
  cursor: pointer;
}

.input-search input {
  background: #fff;
  border-radius: 6px;
  width: 271px;
  border: 1px solid #d1d0d4;
  font-size: 15px;
  height: 39px;
  font-weight: 400;
}

.input-search input::placeholder {
  color: #d1d0d4;
}

input#formBasicPassword {
  box-shadow: none;
  border: 1px solid #d1d0d4;
}

.select-box {
  display: flex;
  justify-content: center;
  width: 70%;
  /* max-width: 70px; */
}

.table-responsive {
  border-top: 1px solid #e6e5e7;
}

.table-responsive thead tr th {
  padding: 14px 10px;
  font-size: 13px;
  color: #444151;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}

.user-checkbox {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile img {
  height: 34px;
  width: 34px;
}

.first-user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user-id p {
  font-size: 15px;
  color: #444151;
  font-weight: 500;
  margin: 0px;
}

.user-id span {
  font-size: 13px;
  color: #6e6b78;
  font-weight: 400;
  margin: 0px;
}

.table-responsive tbody tr td {
  /* max-width: 124px; */
  padding: 5px 10px;
  vertical-align: middle;
}

.user-role p {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
  color: #444151;
}

.debit {
  font-size: 13px;
  font-weight: 500;
  line-height: 47px;
  color: #6e6b78 !important;
}

.pending p {
  color: #ff4c51;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.pending {
  background: #ffefe1;
  text-align: center;
  max-width: 90px;
  padding: 5px;
  border-radius: 6px;
}

.table-responsive td .active {
  background: #dcf6e8;
  text-align: center;
  max-width: 90px;
  padding: 5px;
  border-radius: 6px;
}

.table-responsive td .active p {
  color: #28c76f;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.action {
  display: flex;
  gap: 16px;
  align-items: center;
}

.table-responsive thead tr th:before {
  position: absolute;
  content: "";
  background: #e6e5e7;
  width: 2px;
  height: 26px;
  right: 20px;
  top: 11px;
}

.user-role {
  line-height: 47px;
  color: #444151;
}

.showing-user p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #acaab1;
  margin: 0px;
}

.pagination-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-responsive table {
  margin: 0px;
}

.pagination-section {
  padding: 10px;
}

.pagination-block a.page-link {
  background: #efeef0;
  border-radius: 6px;
  color: #423f4f;
  font-size: 13px;
  font-weight: 400;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
  padding: 0px;
}

.pagination-block ul {
  margin: 0px !important;
  gap: 6px;
}

.pagination-block li.page-item a span {
  font-size: 25px;
}

.pagination-block li.page-item.active .page-link {
  background: #178b46;
  border: 1px solid #178b46;
  border-radius: 6px;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
}

.pagination-block .page-link:focus {
  box-shadow: none !important;
}

.pagination-block li.page-item:hover a {
  background: rgba(21, 138, 68, 0.16);
  color: #178b46;
}

.made-with {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  padding: 10px 0;
  margin-top: 23px;
}

.pixinvent p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #6c6976;
}

.pixinvent p span {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #178b46;
}

.bottom-links a {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #7367f0;
  text-decoration: none;
}

.bottom-links {
  display: flex;
  align-items: center;
  gap: 16px;
}

.set-otp input {
  width: calc(100% / 6) !important;
  border: 1px solid #d1d0d4;
  outline: none;
  height: 50px !important;
}

.set-otp > div {
  justify-content: space-between !important;
  gap: 10px;
}

.notify-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notify-head p {
  margin: 0;
}

.notify-head > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notify-head > div span {
  background-color: #e8e6fc;
  padding: 10px;
  border-radius: 5px;
  color: #7367f0;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
}

.drop-pro-view {
  display: flex;
  gap: 5px;
  align-items: center;
}

.logout-btn {
  background-color: #ff4c51 !important;
  color: #fff !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 3px !important;
  margin: 0px 10px !important;
  border-radius: 8px !important;
  width: 179px !important;
  margin-top: 10px !important;
}

.admin-name h3 {
  color: #444151;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin: 0px;
}

.admin-name p {
  font-size: 13px;
  font-weight: 400;
  color: #acaab1;
  line-height: 20px;
  margin: 0px;
}

.my-profile a {
  color: #444151;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  gap: 9px;
  align-items: center;
  margin-bottom: 5px;
}

.user-img-top button#dropdown-basic {
  background: none;
  border: none;
  padding: 0px;
  width: 50px;
  border-radius: 50%;
  height: 50px;
}

.notification-icon button#dropdown-basic {
  background: none;
  border: none;
  padding: 0px;
  width: 25px;
  border-radius: 50%;
  height: 25px;
}

.notification-icon .dropdown-item:active {
  background: #f8f9fa !important;
  color: #444151 !important;
}

.user-img-top .dropdown-item:active {
  background: #f8f9fa !important;
  color: #444151 !important;
}

.notify-head p {
  color: #444151;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.user-notfic-icon img {
  width: 40px;
  height: 40px;
}

/* end dashboard */

/* my profile page */
.profile-top-box {
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  padding: 18px 18px;
  margin-left: 10px;
  background: #fff;
}

.photo-upload {
  display: flex;
  gap: 25px;
  align-items: center;
}

.btn-up button {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #178b46;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}

.btn-up {
  display: flex;
  gap: 20px;
  align-items: center;
}

.reset-box p {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #eaebed;
  padding: 10px 15px;
  border-radius: 6px;
  color: #808390;
  margin: 0px;
}

p.image-disp {
  color: #6e6b78;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 16px;
  margin-bottom: 0px;
}

.input-profile {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.input-field-width {
  width: 50%;
}

.input-field-width input::placeholder {
  color: #acaab1;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #d1d0d4 !important;
}

.profile-disp label {
  margin-bottom: 3px;
}

.heading-profile h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #444151;
  margin: 0px;
}

.password-req h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  color: #6e6b78;
  margin: 0px;
}

.password-req p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #6e6b78;
  margin: 0px;
  margin-top: 12px;
  display: flex;
  gap: 8px;
}

.user-verification img {
  background: #7367f0;
  padding: 7px;
  height: 33px;
  width: 33px;
  border-radius: 6px;
}

.user-verification img:nth-child(2) {
  background: #ff4c51;
  padding: 7px;
  height: 33px;
  width: 33px;
  border-radius: 6px;
}

.cont-num {
  line-height: 47px;
  color: #444151;
}

a.menu-btn.bar_active ellipse {
  stroke: #fff;
}

/* end */

.identity-proof input[type="checkbox"] {
  position: relative;
  border: 2px solid #158a44;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  border-radius: 6px;
}

.identity-proof input[type="checkbox"]:checked {
  background-color: #158a44;
  opacity: 1;
  border: 1px solid #158a44;
}

.identity-proof input[type="checkbox"]:before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 6px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.side-pages {
  padding: 10px 25px;
}

.side-pages p {
  margin: 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #acaab1;
}

.logo-side {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending {
  text-align: center;
  max-width: 125px;
  padding: 5px;
  border-radius: var(--Border-Radius-border-radius-sm, 4px);
  background: rgba(255, 76, 81, 0.16);
}

.pending p {
  align-items: center;
  justify-content: center;
  gap: 6px;
  display: flex;
}

.toggle-box-top {
  display: flex;
  gap: 5px;
  align-items: center;
}

.toggle-btn {
  cursor: pointer;
}

.toggle-active {
  border-radius: 6px;
  background: linear-gradient(270deg, rgba(21, 138, 68, 0.7) 0%, #158a44 100%);

  /* Light/elevation/primary/primary-shadow-sm */
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}

.toggle-btn p {
  color: #2f2b3de5;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  margin: 0px;
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 8px 18px;
  background: #2f2b3d0f;
  border-radius: 6px;
}

.toggle-active p {
  color: #fff;
}

.image-heading-text {
  padding: 23px 0px 23px 23px;
  background: #fff;
  border-bottom: 1px solid #e6e5e7;
  border-radius: 6px 6px 0px 0px;
}

.image-heading-text h2 {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0px;
}

.toggle-active path {
  stroke: #fff;
  fill: #fff;
}

.pass-input-field {
  padding: 48px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
  max-width: 620px;
}

.max-input-width {
  width: 100%;
}

.bar_active rect {
  stroke: #fff;
}

.create-btn button {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #178b46;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  color: #fff;
  background: linear-gradient(270deg, rgba(21, 138, 68, 0.7) 0%, #158a44 100%);
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}

.create-btn {
  padding-top: 40px;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 0;
}

.image-wapper {
  margin-left: 10px;
  margin-top: 30px;
}

.user-over-view {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.left-side-over-view {
  flex: 0 1 28.3%;
  max-width: 28.3%;
}

.inner-over-box {
  border-radius: 6px;
  box-shadow: 0px 3px 12px 0px rgba(47, 43, 61, 0.14);
  padding: 48px 24px;
}

.my-overview-prof {
  text-align: center;
}

.my-overview-prof img {
  width: 100px;
}

.my-overview-prof h2 {
  color: #2f2b3de5;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
  padding-top: 16px;
}

.my-overview-prof p {
  background: #80839029;
  max-width: fit-content;
  padding: 3px 14px;
  border-radius: 4px;
  color: #808390;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: auto;
  margin-top: 10px;
}

.profile-data {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 25px;
}

.total-view {
  display: flex;
  gap: 13px;
  align-items: flex-start;
}

.text-veiw p {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.text-veiw span {
  color: #2f2b3db2;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.details-heading-text h2 {
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #2f2b3de5;
  margin: 0px;
  border-bottom: 1px solid #2f2b3d1f;
  padding: 10px 0px;
  margin-top: 15px;
}

.user-full-details {
  margin-top: 15px;
}

.user-full-details ul {
  list-style: none;
  padding: 0px;
}

.user-full-details ul {
  list-style: none;
  padding: 0px;
}

.user-full-details ul li p {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 7px;
}

.user-full-details ul li p span {
  color: #2f2b3db2;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.delete-btn-view {
  text-align: center;
  margin-top: 27px;
}

.over-view-btn p {
  border-radius: 6px;
  background: #aeaeae;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  margin: 0px;
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 8px 18px;
}

.right-side-over-view {
  flex: 0 1 70.3%;
  max-width: 70.3%;
}

.outer-shadow-box {
  border-radius: 6px;
  box-shadow: 0px 3px 12px 0px rgba(47, 43, 61, 0.14);
}

.new-over-view {
  border-top: 0px !important;
}

.completed-btn {
  text-align: center;
  max-width: 125px;
  padding: 5px;
  border-radius: var(--Border-Radius-border-radius-sm, 4px);
  background: rgba(21, 138, 68, 0.16);
}

.completed-btn p {
  color: rgba(21, 138, 68, 0.9);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.account-setting {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.delete-account {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgba(47, 43, 61, 0.14);
  padding: 20px;
  width: 100%;
}

.delete-heading-text h1 {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
  padding: 12px 0px;
}

.delete-disp {
  border-radius: 6px;
  background: #ffefe1;
  padding: 11px;
  margin-top: 12px;
  margin-bottom: 20px;
}

.delete-disp p {
  color: #ff9f43;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0px;
  padding-bottom: 5px;
}

.delete-disp span {
  color: #ff9f43;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.check-box-over label {
  margin: 0px;
  color: var(--Light-Text-Primary, text-primary);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.btn-delete-account button {
  border-radius: 6px;
  background: #ff4c51;
  padding: 10px 15px;
  border: 0px;
  color: #fff;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
}

.check-box-over {
  margin-top: 27px;
  margin-bottom: 27px;
}

.pro-perty-heading h2 {
  color: #2f2b3de5;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.property-page {
  padding: 20px 24px;
}

.pro-perty-heading {
  margin-bottom: 19px;
}

.my-property-prof {
  text-align: center;
}

/* .my-property-prof img {
  border-radius: 6px;
  max-width: 300px;
  width: 100%;
} */

.my-property-prof video {
  border-radius: 6px;
  max-width: 300px;
  width: 100%;
  height: 171px;
}

.accept-request-btn button {
  border-radius: 6px;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  margin: 0px;
  padding: 8px 18px;
  border: none;
  outline: none;
  background: linear-gradient(270deg, rgba(21, 138, 68, 0.7) 0%, #158a44 100%);
  width: 100%;
}

.document-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  margin: 18px 25px;
  justify-content: center;
}

.document-images img {
  height: 105px;
  border-radius: 6px;
  width: 160px;
}

.pen-card {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #e6e5e7;
  text-align: center;
  position: relative;
}

.pen-card img {
  height: 180px;
  width: 100%;
  object-fit: cover;
}

.accept-request-btn {
  margin-top: 28px;
}

.rules-policies h1 {
  color: #2f2b3de5;
  text-align: left;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
  margin-bottom: 12px;
}

.rules-policies p {
  margin: 0px;
  color: #2f2b3db2;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.cancellation-policy h2 {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
  padding-bottom: 4px;
  border-bottom: 1px solid #e6e5e7;
}

.cancellation-policy {
  margin-top: 20px;
}

.cancellation-disp p {
  color: #2f2b3db2;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 0px;
}

.cancellation-disp span {
  color: #6a6775;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.cancellation-disp {
  border-radius: 6px;
  background: #f3f3f4;
  padding: 24px;
  margin-top: 13px;
}

.identity-pro ul {
  padding-left: 18px;
  margin-top: 10px;
  display: flex;
  gap: 36px;
}

.identity-pro ul li {
  color: #2f2b3db2;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pro-restrictions ul {
  flex-direction: column;
  gap: 15px;
}

.properties-amenities {
  width: 100%;
}

.inner-properties {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgba(47, 43, 61, 0.14);
  padding: 20px;
  width: 100%;
}

.inner-properties h2 {
  color: #2f2b3de5;
  text-align: left;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
  margin-bottom: 12px;
}

.inner-properties p {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: 6px;
  background: #f3f3f4;
  padding: 10px;
  max-width: 283px;
  margin-bottom: 12px;
}

.line-hr {
  padding-top: 1px;
  border-top: 1px solid #e6e5e7;
}

.checked-in {
  text-align: center;
  max-width: 125px;
  padding: 5px;
  border-radius: var(--Border-Radius-border-radius-sm, 4px);
  background: rgba(21, 138, 68, 0.16);
}

.checked-in p {
  color: #158a44;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.pending-btn {
  border-radius: 6px;
  background: #ff9f4329;
  text-align: center;
  max-width: 125px;
  padding: 5px;
}

.pending-btn p {
  color: #ff9f43;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.booking-info {
  background: #fff;
  padding: 10px 15px;
}

.booking-label label {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.booking-label p {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: 6px;
  background: #2f2b3d0f;
  padding: 10px;
  margin: 0px;
}

.booking-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 50px;
}

.booking-status p {
  color: rgba(21, 138, 68, 0.9);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
}

.booking-status p {
  color: rgba(21, 138, 68, 0.9);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
  border-radius: 6px;
  background: rgba(21, 138, 68, 0.1);
  max-width: 524px;
  padding: 10px;
}

.booking-status {
  margin: 15px 0px;
}

.total-revenue {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgba(47, 43, 61, 0.14);
  padding: 24px;
  width: 100%;
}

.num-of-revenue p {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.num-of-revenue h2 {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}

.num-of-revenue span {
  color: #2f2b3db2;
  font-feature-settings: "liga" off, "clig" off;
  /* Basic Typography/body-2 */
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 153.846% */
}

.select-period-btn button {
  border-radius: 6px;
  background: #158a44;
  padding: 7px 11px;
  border: none;
  outline: none;
  display: flex;
  gap: 9px;
  align-items: center;
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.select-period {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.revenue-pgae {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  padding-left: 10px;
  margin-top: 20px;
}

.create-new-btn button {
  border-radius: 6px;
  background: linear-gradient(270deg, rgba(21, 138, 68, 0.7) 0%, #158a44 100%);
  border: none;
  padding: 7px 17px;
  color: #fff;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
}

.create-notification-page {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 5px 30px 0px rgba(47, 43, 61, 0.18);
  margin: 50px 50px;
  padding: 42px 35px 35px 25px;
}

.category-field {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.category-field .input-select {
  width: 100%;
}

.category-field .modal-input {
  width: 100%;
}

.textarea-input {
  margin-top: 15px;
}

.textarea-input {
  margin-top: 15px;
}

.category-field .input-select label {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin: 0px;
}

.textarea-input .form-label {
  margin: 0px;
}

.modal-input label {
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.textarea-input .form-label {
  margin: 0px;
  color: #2f2b3de5;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.textarea-input textarea.form-control::placeholder {
  color: #2f2b3d66;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.modal-input input::placeholder {
  color: #2f2b3d66;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.input-select select.form-select {
  color: #2f2b3d66;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.download-btn-st {
  border-radius: 6px;
  background: linear-gradient(270deg, rgba(21, 138, 68, 0.7) 0%, #158a44 100%);
}

.download-btn-st p {
  color: #fff;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
}

.create-date-range {
  border-radius: 6px;
  border: 1px solid #158a44;
  padding: 8px 16px;
}

.create-date-range p {
  color: #158a44;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  margin: 0px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.closed {
  max-width: fit-content;
  padding: 5px 10px;
}

.open {
  max-width: fit-content;
  padding: 5px 10px;
}

.support-chat-box-main {
  word-wrap: break-word;
  background-clip: initial;
  background-color: #f3f2f5;
  border-radius: 0.25rem;
  box-shadow: 0 3px 12px 0 #2f2b3d24;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 22px;
  position: relative;
}

.support-msg-box {
  -ms-overflow-style: none !important;
  overflow-y: scroll;
  scrollbar-width: none !important;
  height: 65vh;
  padding: 20px 10px;
}

.user-box {
  margin-bottom: 15px;
}

.user-top-hdng {
  align-items: center;
  display: flex;
}

.user-msg-box {
  background: #fbfbfb;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 20px;
  color: #000;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 35px;
  margin-top: 10px;
  padding: 15px 25px;
}

.user-top-hdng img {
  border-radius: 50px;
  height: 30px;
  margin-right: 10px;
  width: 30px;
}

.user-top-hdng h3 {
  font-size: 22px;
  margin-right: 11px;
  text-transform: capitalize;
}

.user-top-hdng h4 {
  font-size: 11px;
  margin: 0;
}

.user-msg-box p {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.send-box {
  bottom: 0;
  position: absolute;
  width: 95%;
}

.send-box .input-group-text {
  align-items: center;
  background-color: #7367f0;
  border: 0;
  border-radius: 10px !important;
  bottom: 0;
  box-shadow: 0 2px 6px 0 #7367f04d;
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  gap: 10px;
  line-height: 1.5;
  margin: 5px;
  padding: 0.375rem 0.95rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  white-space: nowrap;
  z-index: 99;
}

.send-feild {
  background: #fbfbfb !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 15px !important;
}

.view-green {
  background: linear-gradient(270deg, rgba(21, 138, 68, 0.7) 0%, #158a44 100%);
  color: #fff !important;
  width: 100%;
}

.view-green p {
  color: #fff !important;
}

.booking-id-bold p {
  font-size: 15px;
  font-weight: 700;
}

.curser-pointer {
  cursor: pointer;
}

.dash-graph {
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  padding: 25px 25px;
  border-radius: 6px;
}

.dash-graph h6 {
  color: #2f2b3de5;
  font-family: "Public Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: -0.4px;
  margin: 0px;
}

.profile-picture img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.right-top-bar .user-img-top img {
  border-radius: 50%;
  height: 50px;
  object-fit: cover;
  width: 50px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: transparent !important;
}

.dash-graph .gender-chart {
  height: 400px !important;
  margin: auto;
  max-width: 400px !important;
}

.dash-graph p {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
  color: #2f2b3db2;
}

.bott-order {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.dash-graph p span img {
  margin-right: 11px;
}

.eye-pass {
  position: relative;
}

.eye-pass img {
  position: absolute;
  right: 16px;
  top: 36px;
}

.eye-pass input {
  padding-right: 37px;
}

.suspend-del {
  display: flex;
  align-content: center;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}

.delete-btn button {
  padding: 10px 50px;
}

.btn-delete-account.suspended-btn button {
  border: 1px solid #ff4c51;
  background: transparent;
  color: #ff4c51;
  padding: 10px 41px;
}

.pen-card p {
  font-size: 14px;
  font-weight: 700;
  line-height: 11px;
  color: #2f2b3d;
  text-align: center;
  padding-top: 10px;
}

.travele-user-name p {
  font-size: 15px;
  font-weight: 700;
}

h2.are-sure-text {
  font-size: 23px;
  font-weight: 500;
  line-height: 32px;
  color: #000;
}

button.cancel-btn {
  border-radius: 6px;
  background: #ff4c51;
  padding: 8px 35px;
  border: 0px;
  color: #fff;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
  min-width: 100px;
}

button.done-btn {
  background: linear-gradient(270deg, rgba(21, 138, 68, 0.7) 0%, #158a44 100%);
  color: #fff !important;
  border: none;
  outline: none;
  padding: 7px;
  min-width: 100px;
}

button.cancel-btn:hover {
  background: #ff4c51 !important;
}

.back-btn button {
  width: 50px;
}

button.back-btn-top {
  padding: 7px 20px;
  border: none;
  outline: none;
  background: #000;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
}

.add-padding-and-sp {
  padding: 16px;
}

.img-show-pop {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 10px; */
  position: relative;
}

.img-show-pop > div {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
}

.img-show-pop img {
  height: 400px;
  width: 100%;
  margin: auto;
  object-fit: contain;
  background-color: #fff;
  padding: 50px;
  border-radius: 20px;
}

.img-show-pop > div svg {
  background-color: #fff;
  padding: 5px;
  box-sizing: content-box;
  border-radius: 5px;
  cursor: pointer;
}

.pop-trans-set .modal-content {
  background-color: transparent !important;
  border: 0 !important;
  width: 560px;
  margin: auto;
}

.img-show-pop > div button {
  border: 0;
  background-color: transparent;
}

.are-sure-text {
  font-size: 24px;
  margin: 40px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 0px !important;
}

.property-owner-action {
  display: flex;
  align-items: center;
  gap: 9px;
}

select.form-select {
  font-size: 14px;
}

.basic-pro-info h2 {
  color: var(--black-colour, #171717);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}

.basic-pro-info img {
  width: 100%;
  max-width: 27px;
}

.basic-pro-info {
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid #158a44;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
}

.light-property-tab {
  background: #c4e9d3;
}

.actvie-property-tab {
  background: #158a44;
}

.actvie-property-tab h2 {
  color: #fff !important;
}

.actvie-property-tab svg {
  border: 1px solid #ffff;
  border-radius: 50%;
}

.basic-pro-information {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
}

.basic-field {
  width: 100%;
}

.floors-and-room h3 {
  color: var(--black-colour, #171717);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.floors-and-room {
  border-radius: 6px;
  box-shadow: 0px 3px 12px 0px rgba(47, 43, 61, 0.14);
  padding: 10px 20px;
  margin-top: 24px;
  position: relative;
}

.number-of-room {
  margin-top: 12px;
  padding: 10px 0px;
}

.set-rooms-count {
  display: flex;
  align-items: center;
  gap: 19px;
  border: 1px solid #dee2e6;
  padding: 10px;
  border-radius: 10px;
}

.set-rooms-count p {
  color: var(--dark-grey, #9c9c9c);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.add-rooms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(47, 43, 61, 0.14) 0px 3px 12px 0px;
  border-radius: 6px;
  padding: 8px;
  width: 100%;
}

.add-rooms > p {
  color: var(--black-colour, #171717);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.number-of-room {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.mark-location img {
  width: 100%;
  margin-top: 13px;
}

.delete-red-icon svg {
  background: red;
}

.delete-red-icon svg {
  background: red;
  padding: 5px;
  border-radius: 50%;
}

.delete-red-icon {
  position: absolute;
  top: 7px;
  right: 8px;
  cursor: pointer;
}

.add-new-image {
  position: relative;
}

.add-new-image input {
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.add-new-image {
  position: relative;
  border-radius: 10px;
  background: #f4f4f4;
}

.add-new-image .pen-card svg {
  height: 180px;
}

.photo-sub-heading h3 {
  color: var(--black-colour, #171717);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  padding: 18px 10px 0px;
  margin-left: 14px;
}

.delete-white-icon {
  position: absolute;
  top: 13px;
  right: 15px;
  cursor: pointer;
}

.delete-white-icon svg {
  background: #fff;
  padding: 5px;
  border-radius: 50%;
}

.pen-card video {
  height: 180px;
  width: 100%;
  object-fit: cover;
}

.new-over-view {
  background: #fff;
}

.upload-placeholder {
  border-radius: 10px;
  background: var(--light-grey, #fafafa);
  position: relative;
}
.upload-placeholder input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.center-the-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  height: 202px;
}

.center-the-content p {
  color: var(--dark-grey, #9c9c9c);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.center-the-content span {
  color: var(--dark-grey, #9c9c9c);
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.switch-btn .form-switch .form-check-input {
  width: 42px;
  height: 23px;
}

.switch-btn .form-check-input:checked {
  background-color: #158a44;
  border-color: #158a44;
}

.label-and-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.switch-btn .form-check-input:focus {
  border-color: #158a44 !important;
  outline: 0;
  box-shadow: none;
}

.mandatory-option h3 {
  color: var(--More-Darker, #7e7e7e);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.new-paper-text-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--light-grey, #fafafa);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.new-paper-text-icon p {
  color: var(--black-colour, #171717);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  display: flex;
  gap: 10px;
}

.new-paper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.pick-the-op {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cancell-ation-disp p {
  color: var(--black-colour, #171717);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}

.cancell-ation-disp span {
  color: var(--dark-grey, #9c9c9c);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.title-cancellation h2 {
  color: var(--black-colour, #171717);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.room-sub-heading h3 {
  color: var(--black-colour, #171717);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.deluxe-room p {
  color: var(--black-colour, #171717);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.available-room {
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}

.deluxe-room img {
  width: 100%;
  max-width: 500px;
  object-fit: cover;
  border-radius: 10px;
}

.deluxe-room {
  width: 48%;
  position: relative;
 
}

.add-new-deluxe-room button {
  width: 100%;
  border-radius: 49px;
  border: 2px solid var(--Primary-Green, #158a44);
  padding: 10px;
  max-width: 200px;
  margin: 18px;
  color: var(--Primary-Green, #158a44);

  /* 20 semi bold */

  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.add-new-deluxe-room {
  background: #fff;
}

.error {
  color: red;
}
.rad-delete-icon {
  top: 47px;
}

.view-map-location {
  width: 100%;
  height: 500px;
  position: relative;
}

.image-heading-text span {
  margin-right: 10px;
}
.deluxe-room img {
  width: 100%;
  height: 245px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.radio-op input[type="radio"] {
  accent-color: green;
}
